<template lang="html">
  <div class="language-column">
    <h4 class="header">
      {{ lang }}
    </h4>
    <div class="input">
      <TextInput
        v-model="value"
        :name="lang"
        :placeholder="placeholder"
        multiline
      />
    </div>
    <div class="history">
      <HistoryEntry v-for="(entry, i) of history" :key="i" :entry="entry" />
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'

import TextInput from '@components/forms/TextInput'

import HistoryEntry from './HistoryEntry'

export default {
  components: {
    HistoryEntry,
    TextInput
  },
  props: {
    lang: String
  },
  computed: {
    ...mapState('i18n', {
      fullHistory: state => state.editing.history,
      newValues: state => state.editing.newValues
    }),
    value: {
      get () {
        return this.newValues[this.lang]
      },
      set (value) {
        const {lang} = this
        this.setTranslation({lang, value})
      }
    },
    history () {
      return this.fullHistory[this.lang] || []
    },
    placeholder () {
      var mostRecent = this.history[0]

      return mostRecent ? mostRecent.value : null
    }
  },
  methods: {
    ...mapMutations('i18n', [
      'setTranslation'
    ])
  }
}
</script>

<style lang="scss" scoped>
.language-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 5px;

  .header {
    align-self: center;
  }

  .history {
    display: flex;
    flex-direction: column;

    max-height: 40vh;
    overflow-y: auto;
  }
}
</style>
