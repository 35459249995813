<template lang="html">
  <div class="alert" :class="[`alert-${type}`]">
    <FontAwesomeIcon class="help-icon fa" :class="{large}" :icon="autoIcon" />
    <div class="text">
      <slot />
    </div>
  </div>
</template>

<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faInfoCircle, faExclamationTriangle, faCheck} from '@fortawesome/free-solid-svg-icons'

const iconLookup = {
  info: faInfoCircle,
  warning: faExclamationTriangle,
  danger: faExclamationTriangle,
  success: faCheck
}

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    type: {
      type: String,
      default: 'info',
      validator: value => value in iconLookup
    },
    icon: Object,
    large: Boolean
  },
  computed: {
    autoIcon () {
      return this.icon || iconLookup[this.type]
    }
  }
}
</script>

<style lang="scss" scoped>
.alert {
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  .help-icon {
    margin-right: 10px;

    font-size: 1.5em;

    &.large {
      font-size: 3em;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
  }
}
</style>
