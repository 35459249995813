<template lang="html">
  <div v-if="alwaysShow || $can('Use.TranslationEditor')">
    <TranslationModeToggle title="Shift + F7" />
    <TranslationEditor v-if="key" />
  </div>
</template>

<script>
import {mapState} from 'vuex'

import TranslationModeToggle from './TranslationModeToggle'
import TranslationEditor from './TranslationEditor'

import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    TranslationModeToggle,
    TranslationEditor
  },
  mixins: [
    PermissionMixin
  ],
  props: {
    alwaysShow: Boolean
  },
  computed: {
    ...mapState('i18n', {
      key: state => state.editing.key
    })
  }
}
</script>

<style lang="scss" scoped>
</style>
