<template lang="html">
  <div
    class="translation-mode-toggle"
    :class="{active: editMode}"
    @click="toggleEditMode"
  >
    <FaIcon :icon="icon" size="2x" />
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import {faLanguage} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon as FaIcon} from '@fortawesome/vue-fontawesome'

export default {
  components: {
    FaIcon
  },
  data () {
    return {
      listener: null
    }
  },
  computed: {
    icon () {
      return faLanguage
    },
    ...mapState('i18n', {
      editMode: state => state.editMode
    })
  },
  methods: {
    ...mapMutations('i18n', [
      'toggleEditMode'
    ])
  },
  created () {
    this.listener = document.body.addEventListener('keyup', event => {
      if (event.shiftKey && event.keyCode === 118) { // shift + F7
        this.toggleEditMode()
      }
    })
  },
  beforeDestroy () {
    document.body.removeEventListener('keyup', this.listener)
  }
}
</script>

<style lang="scss" scoped>
.translation-mode-toggle {
  position: fixed;
  z-index: 1; // NOTE to overlay footer, remove once page layout is 100% Vue and correct placement is possible
  bottom: 10px;
  right: 10px;
  transition: color 0.2s;
  cursor: pointer;

  color: lightblue;
  &:hover {
    color: darken(lightblue, 10%);
  }

  &.active {
    color: cornflowerblue;
    &:hover {
      color: lighten(cornflowerblue, 10%);
    }
  }
}
</style>
