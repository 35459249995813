<template lang="html">
  <BsModal
    v-if="key" :value="true"
    blocking
    size="large" title="Edit Translation"
  >
    <HelpBox type="warning">
      Achtung: Übersetzungen werden für alle Portale (inklusive Live-Portale) ohne weitere Sicherheitsabfrage gespeichert. Änderungen können problemlos revidiert werden, aber trotzdem sollte jede Änderung mehrfach kontrolliert werden bevor gespeichert wird.
      <br>
      <br>
      Falls irgendwelche Unklarheiten zur Funktion dieser Seite bestehen ist es empfehlenswert diese vorher mit iXmap zu klären.
    </HelpBox>

    <HelpBox v-if="context" type="warning">
      Es können die Variablen benutzt werden.
      <br>
      <div v-for="(value, key) in context" :key="key" class="context">
        {<span>{{ key }}</span>}
        <i class="fa fa-arrow-right" />
        {{ value }}
      </div>
    </HelpBox>
    <TextInput
      v-model="manualKey"
      name="key"
      label="Key"
      @input="restartEditing"
    />

    <div class="translations">
      <LanguageColumn v-for="lang of supportedLocales" :key="lang" :lang="lang" />
      <FrsLoadingIndicator :loading="loading" />
    </div>

    <div class="buttons">
      <IxButton large @click="cancel">
        Abbrechen
      </IxButton>
      <IxButton save large @click="save">
        Speichern
      </IxButton>
    </div>
  </BsModal>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import IxButton from '@components/IxButton'
import HelpBox from '@components/help/HelpBox'
import TextInput from '@components/forms/TextInput'
import BsModal from '@components/bootstrap/BsModal'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import LanguageColumn from './LanguageColumn'

export default {
  components: {
    IxButton,
    HelpBox,
    FrsLoadingIndicator,
    TextInput,
    LanguageColumn,
    BsModal
  },
  data () {
    return {
      manualKey: '',
      loading: false
    }
  },
  computed: {
    ...mapState('i18n', {
      supportedLocales: 'supportedLocales',
      key: state => state.editing.key,
      context: state => state.editing.context
    })
  },
  methods: {
    ...mapActions('i18n', [
      'startEditing',
      'cancel',
      'save'
    ]),
    restartEditing (key) {
      this.loading = true
      this.startEditing({key}).then(() => {
        this.loading = false
      })
    }
  },
  created () {
    this.manualKey = this.key
  }
}
</script>

<style lang="scss" scoped>
.translations {
  display: flex;
  position: relative;
}
.buttons {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
</style>
