<template lang="html">
  <div class="history-entry">
    <div>{{ entry.value }}</div>
    <div class="metadata">
      <small class="timestamp">{{ $i18n.format(entry.timestamp, 'datetime') }}</small>
      <small class="author">{{ entry.author || 'system' }}</small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    entry: Object
  }
}
</script>

<style lang="scss" scoped>
.history-entry {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  padding: 3px;
  margin-top: 5px;

  &:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }

  .metadata {
    display: flex;
    justify-content: space-between;
  }

  .timestamp, .author {
    color: gray;
  }
}
</style>
